import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AmbulatorioPage = () => (
    <>
    <div className="banner">
      <StaticImage src="../images/dog-ambulatorio.jpg" className="h-96 mt-20 object-cover object-center" title="ambulatorio cane pisa" />
    </div>
    <Layout>
      <Seo title="Chi siamo"></Seo>
        <h1>Pagina in aggiornamento</h1>
    </Layout>
    </>
)

export default AmbulatorioPage